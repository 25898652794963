import React from 'react';
import './TermsAndConditions.css';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
    return (
        <div className="container">
        <Helmet>
        {/* Title of the page */}
        <title>Rus Olive Lodge | Best Hotel in Skardu - Cozy, Comfortable & Affordable</title>

        {/* Meta description */}
        <meta 
          name="description" 
          content="Book your stay at Rus Olive Lodge in Skardu. Enjoy cozy rooms, peaceful ambiance, and affordable rates. Perfect for travelers, tourists, and business guests." 
        />

        {/* Meta keywords */}
        <meta 
          name="keywords" 
          content="Rus Olive Lodge, Skardu hotels, affordable hotels Skardu, cozy lodge Skardu, best hotels in Skardu, places to stay in Skardu, Skardu tourism, hotel near UOB Sundus Campus Skardu, vacation Skardu, hotel in Skardu for tourists" 
        />
        
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Rus Olive Lodge | Best Hotel in Skardu - Cozy & Affordable Stay" />
        <meta 
          property="og:description" 
          content="Looking for a place to stay in Skardu? Rus Olive Lodge offers a comfortable stay with affordable rooms, great service, and a prime location." 
        />
        <meta 
          property="og:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
        <meta property="og:url" content="https://rusolivelodge.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="Rus Olive Lodge | Cozy & Affordable Hotel in Skardu"
        />
        <meta 
          name="twitter:description" 
          content="Book your stay at Rus Olive Lodge, a comfortable hotel offering affordable rooms and great service in the heart of Skardu." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />

        {/* Open Graph locale (optional, good for regional SEO) */}
        <meta property="og:locale" content="en_US" />
        
        {/* Canonical URL - Prevents duplicate content issues */}
        <link rel="canonical" href="https://rusolivelodge.com" />
        <meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
      </Helmet>
            <h1 className="heading">Terms and Conditions</h1>

            <div className="content">
                <h2 className="subheading">Rates</h2>
                <p>Your room rate includes the following:</p>
                <ul>
                    <li>Accommodation</li>
                    <li>Guided activities with a local naturalist or experienced guide</li>
                    <li>All meals (breakfast, lunch, and dinner) and soft drinks</li>
                    <li>Use of the Lodge facilities</li>
                    <li>Shared transfers between Skardu airport and Rus Olive Lodge (from/to select hotels or locations in Skardu city)</li>
                </ul>
                <p>Your room rate does not include:</p>
                <ul>
                    <li>Taxes or service charges</li>
                    <li>Alcoholic beverages</li>
                    <li>Spa services and additional services (available at an additional cost)</li>
                </ul>
                <p>Please note that due to the remote location of Rus Olive Lodge, we recommend planning your arrival during daylight hours to ensure a smooth check-in. The Lodge is accessible by road, but due to the mountainous terrain, travel times may vary.</p>

                <h2 className="subheading">Important</h2>
                <ul>
                    <li>Guests can <strong>directly stay at Rus Olive Lodge</strong> upon arrival in Skardu. There is no need to stay in Skardu City before traveling to the Lodge. We have facilities in place to accommodate guests as soon as they arrive.</li>
                    <li>The Lodge’s accessibility may be affected by weather conditions or road closures, particularly during the winter months. We recommend checking your travel route in advance.</li>
                    <li><strong>Arrival times:</strong> As Skardu is a remote area, we recommend planning your travel to arrive during daylight hours. Please inform us of your expected arrival time, so we can assist you with transportation or guidance.</li>
                </ul>

                <h2 className="subheading">Payment Policies</h2>
                <ul>
                    <li>To confirm your reservation, a <strong>deposit</strong> is required. This deposit will be deducted from the total amount due for your stay.</li>
                    <li><strong>Full payment</strong> is due <strong>14 days</strong> before your check-in date. If you book within <strong>14 days</strong> of your check-in, full payment is required at the time of booking.</li>
                    <li><strong>Non-refundable rates:</strong> For special discounts or promotions, full payment is required at the time of booking. This fee is non-refundable in case of cancellations or changes.</li>
                    <li>Payments can be made via <strong>credit card</strong>, <strong>bank transfer</strong>, or other convenient methods. If you wish to pay in cash upon arrival, please inform us in advance to make arrangements.</li>
                </ul>

                <h2 className="subheading">Cancellation & Modification Policies</h2>
                <p>We understand that plans can change, so we offer a flexible cancellation policy. Please refer to the details below:</p>
                <ul>
                    <li><strong>Cancellations made up to 3 days before check-in</strong>: No cancellation fee. You will receive a full refund (if applicable), or you may choose to reschedule your stay for another date within the same year without additional charges.</li>
                    <li><strong>Cancellations made between 2 and 1 day before check-in</strong>: A <strong>25% cancellation fee</strong> will apply to the total booking amount. You may also reschedule your booking without penalty, subject to availability.</li>
                    <li><strong>Cancellations made on the day of arrival or no-shows</strong>: The full booking amount will be charged as a <strong>no-show fee</strong>. If you encounter a legitimate issue (e.g., travel delay due to weather), please contact us immediately, and we will do our best to accommodate you or assist with rescheduling.</li>
                    <li><strong>Peak Season</strong> (May 15th – September 15th):
                        <ul>
                            <li><strong>Cancellations made 3 or more days before check-in</strong>: No charge.</li>
                            <li><strong>Cancellations made between 2 and 1 day before check-in</strong>: A <strong>50% cancellation fee</strong> applies.</li>
                            <li><strong>Cancellations on the day of arrival or no-shows</strong>: The full booking amount will be charged.</li>
                        </ul>
                    </li>
                    <li><strong>Weather-related cancellations</strong>: If your journey is delayed or canceled due to weather conditions, road closures, or other unforeseen factors, we will make every effort to accommodate you and may adjust cancellation fees accordingly.</li>
                </ul>

                <h2 className="subheading">Travel Insurance</h2>
                <p>We highly recommend purchasing <strong>travel insurance</strong> for your trip. While we strive to make your stay enjoyable, travel insurance offers peace of mind and financial protection against unforeseen events, such as medical emergencies, trip cancellations, lost luggage, or weather disruptions.</p>

                <h2 className="subheading">Additional Information</h2>
                <ul>
                    <li><strong>Changes to your stay:</strong> Any modifications (such as changing dates or room types) made after the booking will be treated as a <strong>cancellation</strong> and subject to the cancellation policy above.</li>
                    <li><strong>Arrival times:</strong> We recommend you plan your journey so you arrive during daylight hours, especially if you're traveling by road. Let us know your expected arrival time so we can assist you with transportation and make your check-in smoother.</li>
                </ul>

                <h2 className="subheading">Thank You for Choosing Rus Olive Lodge!</h2>
                <p>We look forward to welcoming you to the serene beauty of <strong>Skardu</strong> and making your stay as comfortable and memorable as possible. If you have any further questions or need assistance with your reservation, please feel free to contact us.</p>
            </div>
        </div>
    );
}

export default TermsAndConditions;
