import React from "react";
import facebook from './assets/New folder/facebook.png';
import instagram from './assets/New folder/instagram.png';
import './Home.css'
import youtube from './assets/New folder/youtube.png';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Home = () => {
  return (
    <div className="body">
     
      <Helmet>
        {/* Title of the page */}
        <title>Rus Olive Lodge | Best Hotel in Skardu - Cozy, Comfortable & Affordable</title>

        {/* Meta description */}
        <meta 
          name="description" 
          content="Book your stay at Rus Olive Lodge in Skardu. Enjoy cozy rooms, peaceful ambiance, and affordable rates. Perfect for travelers, tourists, and business guests." 
        />

        {/* Meta keywords */}
        <meta 
          name="keywords" 
          content="Rus Olive Lodge, Skardu hotels, affordable hotels Skardu, cozy lodge Skardu, best hotels in Skardu, places to stay in Skardu, Skardu tourism, hotel near UOB Sundus Campus Skardu, vacation Skardu, hotel in Skardu for tourists" 
        />
        
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Rus Olive Lodge | Best Hotel in Skardu - Cozy & Affordable Stay" />
        <meta 
          property="og:description" 
          content="Looking for a place to stay in Skardu? Rus Olive Lodge offers a comfortable stay with affordable rooms, great service, and a prime location." 
        />
        <meta 
          property="og:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
        <meta property="og:url" content="https://rusolivelodge.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="Rus Olive Lodge | Cozy & Affordable Hotel in Skardu"
        />
        <meta 
          name="twitter:description" 
          content="Book your stay at Rus Olive Lodge, a comfortable hotel offering affordable rooms and great service in the heart of Skardu." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />

        {/* Open Graph locale (optional, good for regional SEO) */}
        <meta property="og:locale" content="en_US" />
        
        {/* Canonical URL - Prevents duplicate content issues */}
        <link rel="canonical" href="https://rusolivelodge.com" />
        <meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
      </Helmet>
 <div className="MainVid" style={{ paddingBottom: '20vh' }}>
  <video loading="lazy" className="mainvideo"
    src="/Homeimg/mainvid.mp4" 
    autoPlay 
    muted 
    playsInline
    loop 
    style={{ width: '100vw', height: '100vh' }} 
  />
</div>
    <div className="morning1" style={{ display: 'flex',flexDirection:'row'}}>
      <img loading="lazy" className="garden" src="Homeimg/morninggarden.jpg" alt=""  height="800vh"  style={{ width: '300vw' }}/>
    
    <div className="welcomemain" style={{display:'flex',flexDirection:"column"}} >
    <h1 className="welcome" style={{paddingLeft: '5vw',paddingTop:'5vh', fontSize:'8vh'}}>Welcome</h1>
      <p className="welcomep" style={{paddingLeft: '5vw', fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal', fontSize:'3.5vh' ,color:'GrayText'}}>
Rus Olive Lodge Skardu is a Climate Award winner, honored for its tribute to 
nature. Our boutique lodge offers breathtaking views of Skardu's spectacular
 mountains and valleys, with some rooms and common areas overlooking the serene landscapes.
  The peaceful surroundings ensure a calming retreat, perfect for a holiday in the mountains. 
  A sanctuary for birdwatchers and nature lovers, 
Rus Olive Lodge provides the ultimate escape into nature's tranquility.</p>

    <Link to="/check" className="book-now-btn">
    Book Now 
    </Link>
      
        

    </div>
   
    </div>

    <div className="morning2" style={{ display: 'flex',flexDirection:'row' , paddingTop:'0vh'
    }}>
     
    
    <div className="about" style={{display:'flex',flexDirection:"column"}} >
    <h1  className="welcome" style={{paddingLeft: '5vw', fontSize:'8vh'}}>ABOUT US</h1>
    <h className="aboutsub" style={{paddingLeft: '5vw', fontSize:'5vh',fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal'}}>Our Story</h>
      <p className="welcomep" style={{paddingLeft: '5vw',paddingRight:'5vw', fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal', fontSize:'3.5vh' ,color:'GrayText'}}>
      Rus Olive Lodge Skardu is a Climate Award winner for its tribute to nature. 
      Our boutique lodge is the labor of love of two nature enthusiasts and bird lovers,
       Yasir and Shayan. Named after the abundant Russian olive trees surrounding the property, 
       it offers a peaceful, relaxing spot for family vacations, a retreat after mountain treks,
        or a quiet place for painting, writing, or working on your research. The charming wooden
         cabins nestled among
       the trees, with views of the stunning mountains, provide the perfect escape for nature lovers.

</p>
<h className="aboutsub" style={{paddingLeft: '5vw', fontSize:'5vh',fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal'}}>Where We Are Located</h>
<p className="welcomep" style={{paddingLeft: '5vw',paddingRight:'5vw', fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal', fontSize:'3.5vh' ,color:'GrayText'}}>
Rus Olive Lodge Skardu is dedicated to preserving the natural beauty of the region, offering a harmonious blend of comfort and eco-conscious living. Our boutique lodge is located 
in Sundus, Skardu, just 15-20 minutes from Skardu Airport. The famous Katpana Cold Desert and Katpana Lake
 are within walking distance, while
 popular attractions such as Blink Lake, Shigar, Sarfanga, and Kachura Lake are all just 15-40 minutes away

</p>
    </div>
  <div className="nightimgmain" style={{display:'flex',flexDirection:'column'}}>
  <img loading="lazy" className="nightimg" src="Homeimg/night1 (1).jpg" alt=""  height="435vh"  style={{ width: '50vw' }}/>
  <img loading="lazy" className="nightimg" src="Homeimg/night1 (2).jpg" alt=""  height="435vh"  style={{ width: '50vw' }}/>
  {/* <p style={{paddingLeft: '270px', fontFamily: 'Lato , sans-Serif', fontWeight: '300', fontStyle: 'normal', fontSize:'10px' ,color:'green'}}> */}
  {/* Rus Olive in the Moonlight</p> */}
  </div>
    </div>
   









   {/* Footer */}
   <div className='footer'>
    <div className='sb_footer section_padding'>
    <div className='sb_footer-links'>
    <div className='sb_footer-links_div'>
    <h4 className='font'> Lets Keep in touch!</h4>
    <a href='/employer'>
        <p> Find us on any of this plaforms, we response 1-2 business days</p>
    </a>

    <div className='socialmedia'>
<a href="https://www.facebook.com/profile.php?id=61562902921721&mibextid=ZbWKwL"><p className='social'> <img src={facebook} alt="" /></p></a>
<a href="https://www.instagram.com/rusolivelodge?igsh=MWJnNncwbW92Y2FsbA=="><p className='social'> <img src={instagram} alt="" /></p></a>
<a href=""><p className='social'  > <img src={youtube} alt="" /></p></a>
</div>
    </div>

    <div className='sb_footer-links_div'>
    <h4 className='font'> USEFUL LINK</h4>
    <a href='/'>
        <p> About us</p>
    </a>
    <a href='/employer'>
        <p> Blog</p>
    </a>
   
  
    <Link to="/check">
    <p> Book a Room</p>
    </Link>
 
    </div>

    
    <div className='sb_footer-links_div'>
    <h4 className='font'> OTHER RESOURCES</h4>
  
    <a href='/terms'>
       <Link to="/term" >
         <p>Term and Conditions</p>
    </Link>
    </a>
    <Link to="/term" >
         <p>Privacy & Policy</p>
    </Link>
    
        <Link to="/contact">
        <p> Contact Us</p>
    </Link>
   </div>


 

    
        
    </div>

    

   <hr></hr>
   <div className='sb_footer-below'>
    <div className='sb_footer-copyright'>
        <p> 
        @{new Date().getFullYear()}  Rus Olive Lodge Skardu. All right reserved.        
        </p>
    </div>
   </div>
    </div>


    </div>
    </div>
  );
};
