import React from "react";
import facebook from './assets/New folder/facebook.png';
import instagram from './assets/New folder/instagram.png';
import './Home.css'
import youtube from './assets/New folder/youtube.png';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Room = () => {
  return (
    <div className="body">
      <Helmet>
        {/* Title of the page */}
        <title>Rus Olive Lodge | Best Hotel in Skardu - Cozy, Comfortable & Affordable</title>

        {/* Meta description */}
        <meta 
          name="description" 
          content="Book your stay at Rus Olive Lodge in Skardu. Enjoy cozy rooms, peaceful ambiance, and affordable rates. Perfect for travelers, tourists, and business guests." 
        />

        {/* Meta keywords */}
        <meta 
          name="keywords" 
          content="Rus Olive Lodge, Skardu hotels, affordable hotels Skardu, cozy lodge Skardu, best hotels in Skardu, places to stay in Skardu, Skardu tourism, hotel near UOB Sundus Campus Skardu, vacation Skardu, hotel in Skardu for tourists" 
        />
        
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Rus Olive Lodge | Best Hotel in Skardu - Cozy & Affordable Stay" />
        <meta 
          property="og:description" 
          content="Looking for a place to stay in Skardu? Rus Olive Lodge offers a comfortable stay with affordable rooms, great service, and a prime location." 
        />
        <meta 
          property="og:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
        <meta property="og:url" content="https://rusolivelodge.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="Rus Olive Lodge | Cozy & Affordable Hotel in Skardu"
        />
        <meta 
          name="twitter:description" 
          content="Book your stay at Rus Olive Lodge, a comfortable hotel offering affordable rooms and great service in the heart of Skardu." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />

        {/* Open Graph locale (optional, good for regional SEO) */}
        <meta property="og:locale" content="en_US" />
        
        {/* Canonical URL - Prevents duplicate content issues */}
        <link rel="canonical" href="https://rusolivelodge.com" />
        <meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
      </Helmet>
<div style={{display: 'flex', width: '100vw', justifyContent: 'center', alignItems: 'center'}}>
  <img className="hotelLogo" style={{height: '40vh'}} src="favicon.png" alt=""/>
</div >
<div className="room" style={{ display: 'flex', flexWrap: 'wrap', width: '100vw', justifyContent: 'space-evenly' }}>
  {/* First pair of heading, image, and paragraph */}
  <div className="innerroom" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '2vh' }}>
    <h1 className="roomh1" style={{  fontSize: '3.3vh', fontFamily: 'Cabin, sans-serif', fontWeight: '500', fontStyle: 'normal' }}>
    Premium Triple Room
    
    </h1>
    <div className="pimg" style={{height: '50vh', width: '40vw'}}>
    <img loading="lazy" className="roomimg" style={{ height: '40vh', width: '40vw' }} src="/Homeimg/markhor.jpg" alt="" />
    <p className="welcomep" style={{ fontSize: '2.5vh',  fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal',color:'GrayText', marginTop: '1vh' }}>
    The Markhor Premium Triple Room on the 1st floor offers stunning Skardu mountain and garden views. With a private balcony, one double bed,
     and one single bed, it's perfect for families or small groups seeking comfort and relaxation. </p>
    </div>
  </div>

  {/* Second pair of heading, image, and paragraph */}
  <div className="innerroom" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '2vh' }}>
    <h1 className="roomh1" style={{ fontSize: '3.3vh', fontFamily: 'Cabin, sans-serif', fontWeight: '500', fontStyle: 'normal' }}>
      Family Twin Room
    </h1>
    <div className="pimg" style={{height: '50vh', width: '40vw'}}>
    <img loading="lazy" className="roomimg" style={{ height: '40vh', width: '40vw' }} src="/Homeimg/ibex.jpg" alt="" />
    <p className="welcomep" style={{ fontSize: '2.5vh',  fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal'  ,color:'GrayText', marginTop: '1vh' }}>
    The Family Twin Rooms include Ibex and Brown Bear. Ibex is on the 1st floor, offering peaceful backyard views and the sounds of nature, while Brown Bear is on the ground floor, spacious with a beautiful garden view.
     Both rooms feature one double bed, perfect for families seeking comfort and tranquility. </p>
    </div>
  
  </div>

  {/* Third pair of heading, image, and paragraph */}
  <div className="innerroom" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '2vh' ,paddingTop:'15vh'}}>
    <h1 className="roomh1" style={{ fontSize: '3.3vh', fontFamily: 'Cabin, sans-serif', fontWeight: '500', fontStyle: 'normal' }}>
    Duo Comfort Rooms
    </h1>
    <div className="pimg" style={{height: '50vh', width: '40vw'}}>
    <img loading="lazy" className="roomimg"  style={{ height: '40vh', width: '40vw' }} src="/Homeimg/marmot.jpg" alt="" />
    <p className="welcomep" style={{ fontSize: '2.5vh',  fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal'  ,color:'GrayText', marginTop: '1vh' }}>
    Our Duo Rooms, Bluesheep and Marmot, are designed to provide a cozy and intimate setting, making them ideal for couples. Each room offers a comfortable, 
    relaxing space with modern amenities, perfect for those seeking a peaceful and tranquil retreat. </p>
  
    </div>
  </div>

  {/* Fourth pair of heading, image, and paragraph */}
  <div className="innerroom"  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '2vh' ,paddingTop:'15vh'}}>
    <h1 className="roomh1" style={{ fontSize: '3.3vh', fontFamily: 'Cabin, sans-serif', fontWeight: '500', fontStyle: 'normal', }}>
    Classic Triple Room
    </h1>
    <div className="pimg" style={{height: '50vh', width: '40vw'}}>
    <img loading="lazy" className="roomimg" style={{ height: '40vh', width: '40vw' }} src="/Homeimg/yak.jpg" alt="" />
    
    <p className="welcomep" style={{ fontSize: '2.5vh',  fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal' ,color:'GrayText', marginTop: '1vh' }}>
    Located on the ground floor, the Yak room offers peaceful backyard views in a cool, quiet setting. It features one double bed and one single bed,
     making it perfect for small families or groups seeking comfort and relaxation  </p>
   
    </div>
   
  </div>
  
</div>




  {/* Footer */}
  <div className='footer' >
 
    <div className='sb_footer section_padding'>
    <div className='sb_footer-links'>
    <div className='sb_footer-links_div'>
    <h4 className='font'> Lets Keep in touch!</h4>
    <a href='/employer'>
        <p> Find us on any of this plaforms, we response 1-2 business days</p>
    </a>

    <div className='socialmedia'>
<a href="https://www.facebook.com/profile.php?id=61562902921721&mibextid=ZbWKwL"><p className='social'> <img src={facebook} alt="" /></p></a>
<a href="https://www.instagram.com/rusolivelodge?igsh=MWJnNncwbW92Y2FsbA=="><p className='social'> <img src={instagram} alt="" /></p></a>
<a href=""><p className='social'  > <img src={youtube} alt="" /></p></a>
</div>
    </div>

    <div className='sb_footer-links_div'>
    <h4 className='font'> USEFUL LINK</h4>
    <a href='/'>
        <p> About us</p>
    </a>
    <a href='/employer'>
        <p> Blog</p>
    </a>
   
  
    
 
    </div>

    
    <div className='sb_footer-links_div'>
    <h4 className='font'> OTHER RESOURCES</h4>
  
    <a href='/terms'>
       <Link to="/term" >
         <p>Term and Conditions</p>
    </Link>
    </a>
    <Link to="/term" >
         <p>Privacy & Policy</p>
    </Link>
    
        <Link to="/contact">
        <p> Contact Us</p>
    </Link>
   </div>


 

    
        
    </div>

    

   <hr></hr>
   <div className='sb_footer-below'>
    <div className='sb_footer-copyright'>
        <p> 
        @{new Date().getFullYear()}  Rus Olive Lodge Skardu. All right reserved.        
        </p>
    </div>
   </div>
    </div>


    </div>
    </div>
   
  );
};
