import './index.css';
import './App.css';
import { Helmet } from 'react-helmet';

import NavBar from "./Nav/NavBar";
import { Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./Nav/Pages/Home";
import { Room } from "./Nav/Pages/Room";
import { Restaurant } from "./Nav/Pages/Restaurant";
import { Contact } from "./Nav/Pages/Contact";
import { Roomcheck } from "./Nav/Pages/Roomcheck";
import TermsAndConditions from "./TermsAndConditions";

function App() {
  const location = useLocation(); // Correct usage of useLocation()

  const isTermsPage = location.pathname === "/term"; // Check if we are on the Terms page

  return (
    <>
     <Helmet>
        <title>Rus Olive Lodge</title>
        <meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
        <meta name="description" content="A description of your page for SEO" />
        <meta name="keywords" content="React, SEO, Web Development" />
      </Helmet>
      {/* Render NavBar only if not on the Terms page */}
      {!isTermsPage && <NavBar />}

      <div className="pages">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/check" element={<Roomcheck />} />
          <Route path="/about" element={<Room />} />
          <Route path="/blog" element={<Restaurant />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/term" element={<TermsAndConditions />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
