import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
import { Helmet } from 'react-helmet';

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
     <Helmet>
        {/* Title of the page */}
        <title>Rus Olive Lodge | Best Hotel in Skardu - Cozy, Comfortable & Affordable</title>

        {/* Meta description */}
        <meta 
          name="description" 
          content="Book your stay at Rus Olive Lodge in Skardu. Enjoy cozy rooms, peaceful ambiance, and affordable rates. Perfect for travelers, tourists, and business guests." 
        />

        {/* Meta keywords */}
        <meta 
          name="keywords" 
          content="Rus Olive Lodge, Skardu hotels, affordable hotels Skardu, cozy lodge Skardu, best hotels in Skardu, places to stay in Skardu, Skardu tourism, hotel near UOB Sundus Campus Skardu, vacation Skardu, hotel in Skardu for tourists" 
        />
        
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Rus Olive Lodge | Best Hotel in Skardu - Cozy & Affordable Stay" />
        <meta 
          property="og:description" 
          content="Looking for a place to stay in Skardu? Rus Olive Lodge offers a comfortable stay with affordable rooms, great service, and a prime location." 
        />
        <meta 
          property="og:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
        <meta property="og:url" content="https://rusolivelodge.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="Rus Olive Lodge | Cozy & Affordable Hotel in Skardu"
        />
        <meta 
          name="twitter:description" 
          content="Book your stay at Rus Olive Lodge, a comfortable hotel offering affordable rooms and great service in the heart of Skardu." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
<meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
        {/* Open Graph locale (optional, good for regional SEO) */}
        <meta property="og:locale" content="en_US" />
        
        {/* Canonical URL - Prevents duplicate content issues */}
        <link rel="canonical" href="https://rusolivelodge.com" />
      </Helmet>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <span>Rus Olive Lodge</span>
            {/* <i className="fas fa-code"></i> */}
            <span className="icon">
        
            </span>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Rooms
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Restaurant
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuOpen />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
